<template>
  <div class="bc-works">
    <div class="component component__padding page">
      <div class="component__title">
        {{ $t('WORKS.TITLE') }}
      </div>
      <div class="component__subtitle">
        <span v-if="error">
          {{ $t('WORKS.EMPTY') }}
        </span>
        <span v-else>
          {{ $t('WORKS.SUBTITLE') }}
        </span>
      </div>

      <div class="bc-works__content">
        <img class="bc-works__content__card"
             :src="picture.picture" alt=""
             v-for="picture in worksList" :key="picture.id">
      </div>

    </div>


  </div>
</template>

<script>
// import {usePageTitle} from "@/utils";
import {computed} from "vue";
import {store} from "@/store";
import {useHead} from "@vueuse/head";

export default {
  name: "bc-works",
  components: {
  },
  created() {
    useHead({
      // Can be static or computed
      title: computed(() => store.getters.getSeoDataForPage('WORKS')?.title),
      meta: [
        {
          name: `description`,
          content: computed(() => store.getters.getSeoDataForPage('WORKS')?.description),
        },
      ],
    })
    // usePageTitle(
    //     computed(() => store.getters.getSeoDataForPage('WORKS')?.title),
    //     computed(() => store.getters.getSeoDataForPage('WORKS')?.description))
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get('/api/works/').then(resp => {
        this.worksList = resp.data
        if (!this.worksList.length) {
          this.error = true
        }
      }).catch(err => {
        this.error = true
        console.error(err)
      })
    },
  },
  data() {
    return {
      worksList: [],
      error: false
    }
  }
}
</script>

<style scoped lang="scss">
.bc-works {
  .component__subtitle {
    text-align: center;
  }
  &__content {
    min-height: 40vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 100px;

    &__card {
      max-width: 30%;
      &:nth-child(2) {
        margin-top: 50px;
      }
      &:not(:nth-last-child(-n+3)) {
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 900px){
  .bc-works {
    &__content {
      flex-wrap: wrap;
      justify-content: space-around;

      &__card {
        max-width: 45%;
        width: 45%;

        &:nth-child(n) {
          margin-top: 20px;
        }
        &:not(:nth-last-child(-n+3)) {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 600px){
  .bc-works {
    &__content {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-around;
      margin-top: 0;

      &__card {
        max-width: 100%;
        width: 100%;

        &:nth-child(2n+1) {
          margin-top: 40px;
        }
        &:nth-child(n) {
          margin-top: 40px;
        }
      }
    }
  }
}
</style>